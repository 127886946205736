var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantOrganizeForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入名称", width: _vm.width },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数量",
                        rules: [
                          {
                            required: true,
                            message: "请输入数量",
                            trigger: "blur",
                          },
                        ],
                        prop: "number",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入数量",
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "change",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.statusOps, width: _vm.width },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "图片",
                        rules: [
                          {
                            required: true,
                            message: "请上传图片",
                            trigger: "change",
                          },
                        ],
                        prop: "img",
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: { action: _vm.uploadURL, imgUrls: _vm.form.img },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "img", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "img", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "注：建议宽640px高640px，图片小于512KB"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间段" } },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "rangedatetimer",
                          format: "yyyy-MM-dd HH:mm",
                          startTime: _vm.form.startDatetime,
                          endTime: _vm.form.endDatetime,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(_vm.form, "startDatetime", $event)
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(_vm.form, "startDatetime", $event)
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(_vm.form, "endDatetime", $event)
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(_vm.form, "endDatetime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }