<template>
  <div class="TenantOrganizeForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="名称" :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]" prop="name">
            <v-input v-model="form.name" placeholder="请输入名称" :width="width" />
          </el-form-item>
          <el-form-item label="数量" :rules="[{ required: true, message: '请输入数量', trigger: 'blur' }]" prop="number">
            <v-input-number v-model="form.number" placeholder="请输入数量" controls controlsPosition="right" :width="width" />
          </el-form-item>
          <el-form-item label="状态" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]" prop="status">
            <v-select v-model="form.status" :options="statusOps" :width="width" />
          </el-form-item>
          <el-form-item label="图片" :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]" prop="img">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.img">
              <template #tip>
                <span>注：建议宽640px高640px，图片小于512KB</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="时间段">
            <v-datepicker
              type="rangedatetimer"
              format="yyyy-MM-dd HH:mm"
              :startTime.sync="form.startDatetime"
              :endTime.sync="form.endDatetime"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
  
<script>
import { createURL, editURL, queryURL, uploadURL } from './api'
import { setStatusOps } from './map'
import { vUploader } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'TenantOrganizeForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 200,
      uploadURL,
      statusOps: setStatusOps(),
      form: {
        id: undefined,
        name: '',
        number: undefined,
        status: undefined,
        img: [],
        startDatetime: '',
        endDatetime: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editURL
      this.$refs.formPanel.getData({ id })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
    }
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      return true
    }
  }
}
</script>
