import { mapHelper } from 'common/utils'

// 按钮列表
const btnOps = [
  {
    text: '新增组织',
    title: '新增组织',
    code: 1,
    value: 'addSectionBtn'
  },
  {
    text: '删除组织',
    title: '删除组织',
    code: 2,
    value: 'deteleBtn'
  },
  {
    text: '编辑组织',
    title: '编辑组织',
    code: 3,
    value: 'editBtn'
  },
  {
    text: '移动组织',
    title: '移动组织',
    code: 4,
    value: 'moveBtn'
  },
  {
    text: '设置公司',
    value: 'setBtn'
  },
  {
    text: '关闭公司',
    value: 'clostCompanyBtn'
  },
  {
    text: '新增成员',
    title: '新增成员',
    code: 'component',
    type: 'component',
    componentName: 'accountForm',
    value: 'addMemberBtn'
  },
]

// // 树类型列表
// const treeType = [
//   {
//     text: '平台职能部门',
//     permission: 'platformBtn',
//     value: 1
//   },
//   {
//     text: '物业管理公司',
//     permission: 'companyBtn',
//     value: 2
//   },
//   {
//     text: '社区治理单位',
//     permission: 'communityBtn',
//     value: 3
//   }
// ]
// const {
//   map: treeTypeMap,
//   setOps: treeTypeOps
// } = mapHelper.setMap(treeType)

// 性别
const sex = [
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
const {
  map: sexMap,
  setOps: sexOps
} = mapHelper.setMap(sex)

// 账号状态
const permission = [
  {
    text: '后台',
    value: 0
  },
  {
    text: '物管端',
    value: 1
  },
  {
    text: '业主端',
    value: 2
  }
]
const {
  map: permissionMap,
  setOps: permissionOps
} = mapHelper.setMap(permission)

// 账号状态
const status = [
  {
    text: '有效',
    value: 0
  },
  {
    text: '已过期',
    value: 1
  },
  {
    text: '已关闭',
    value: 2
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 所属类别
const subHandle = [{
  text: '项目应用',
  value: 0
},
{
  text: '电商应用',
  value: 1
}
]

const {
map: subHandleMap,
setOps: setSubHandleOps
} = mapHelper.setMap(subHandle)

// 应用类型
const subType = [{
  text: '原生功能入口',
  value: 8
},
{
  text: 'H5页面入口',
  value: 7
},
{
  text: '电商商家入口',
  value: 2
},
{
  text: '商品入口',
  value: 11
},
{
  text: '商品标签入口',
  value: 13
}
]

const {
map: subTypeMap,
setOps: setSubTypeOps
} = mapHelper.setMap(subType)

// 状态
const appStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
}
]

const {
map: appStatusMap,
setOps: setStatusOps
} = mapHelper.setMap(appStatus)

export {
  btnOps,
  // treeTypeOps,
  // treeTypeMap,
  sexOps,
  sexMap,
  permissionOps,
  permissionMap,
  statusOps,
  statusMap,
  subHandleMap,
  setSubHandleOps,
  subTypeMap,
  setSubTypeOps,
  appStatusMap,
  setStatusOps,
}
